<!-- 选号工具标题栏 -->
<template>
    <div class="ssq-header">
        <ol class="pos">当前位置：</ol>
        <div class="ssq-title">
            <ul>
                <ol>
                    <router-link :to="item.rou" v-for="(item, i) in list" :key="i">
                        <li :class="{ active: i == isActive }" @click="one(i)">
                            {{ item.title }}
                        </li>
                    </router-link>
                    <div class="clear"></div>
                </ol>
            </ul>
            <div class="clear"></div>
        </div>
    </div>
</template>

<script>

    //import '../js/font.js' //js文件引入，注意路径
    //import {myfun} from '../js/test.js' //js文件引入，注意路径
    //import Introduction from '@/views/Introduction.vue'

    export default {
        name: 'num-header',
        components: {

        },
        data() { // 数据源
            return {
                list: [
                    { title: "双色球-选号工具", rou: "/ssq-number" },
                    { title: "快乐8-选号工具", rou: "/klb-number" },
                    { title: "福彩3D-选号工具", rou: "/sd-number" },
                    { title: "七乐彩-选号工具", rou: "/qlc-number" },
                    { title: "大乐透-选号工具", rou: "/dlt-number" },
                    { title: "排列3/5-选号工具", rou: "/pl35-number" },
                    { title: "七星彩-选号工具", rou: "/qxc-number" },

                ],
                isActive: 0,
            }
        },
        mounted() {

        },
        computed: {

        },
        methods: { // 函数体部分，js的主要逻辑控制   

            one(i) {
                this.isActive = i
            },
        
        },
        props: {
        },
        watch: {
            // header(){
            //     this.head=this.header
            // }
        }
    }
</script>

<style scoped>
    /* @import './swiper.css'; */
    /* *{
        border:1px solid red;
    } */
    .pos {
        text-align: left;
    }

    .ssq-title {
        border: 1px solid rgb(201, 197, 197);

    }

    /* .ssq-title ul{
        height:50px;
        border:1px solid rgb(212, 16, 16);
    } */
    .ssq-title ul li {
        margin: 0;
        padding: 0;
        float: left;
        margin-left: 1%;
        margin-top: 8px;
        height: 30px;
        line-height: 30px;
        width: 10%;
        border-radius: 10px;
        color: rgb(138, 113, 5);
        background-color: rgb(219, 213, 213);
    }

    .ssq-title ol {
        font-size: 15px;
    }

    .ssq-title ul li:hover {
        color: rgb(249, 251, 252);
        background-color: rgb(32, 8, 252);
    }

    .ssq-title .active {
        color: rgb(249, 251, 252);
        background-color: rgb(32, 8, 252);
    }
</style>